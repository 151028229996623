<template>
  <div class="container footer-placeholder">
    <a :href="onLine" target="_blank"><img src="/static/img/cloud-ali-banner.jpg" /></a>
    <!-- 产品服务 -->
    <Cloud_v2 />
    <div class="cloud-container">
      <!-- <Coupons /> -->
      <CloudItemBox v-for="(item, index) in projects" :key="index" :title="item.name" :list="item.projects"
        @detail="goDetail(item.id)" />
    </div>
  </div>
  <Footer />
</template>
<script>
import { onLine } from "@/data.ts";
import Footer from "@/components/Footer.vue";
import Cloud_v2 from "@/components/home/Cloud_v2.vue";
import CloudItemBox from "@/components/cloud/CloudItemBox.vue";
import Coupons from "@/components/home/Coupons.vue";
export default {
  components: {
    Footer,
    Cloud_v2,
    CloudItemBox,
    Coupons,
  },
  data() {
    return {
      onLine,
      projects: [],
    };
  },
  mounted() {
    fetch("/static/data.json")
      .then((response) => response.json())
      .then((data) => {
        this.projects = data;
      });
  },
  methods: {
    goDetail(id) {
      this.$router.push(`/cloud/alicloud/detail/${id}`);
    },
  },
};
</script>
<style lang="less" scoped>
.container {
  a {
    display: block;
  }

  img {
    width: 100%;
    pointer-events: none;
  }

  .cloud-container {
    padding-bottom: 40px;
    position: relative;

    &::after {
      content: "";
      position: absolute;
      left: 0;
      right: 0;
      top: 25px;
      bottom: 0;
      background: linear-gradient(0deg, #284cb9, #9a0000);
      z-index: -1;
    }
  }
}
</style>